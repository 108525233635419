* {
	vertical-align: baseline;
	font-weight: inherit;
	font-family: inherit;
	font-style: inherit;
	font-size: 100%;
	border: 0 none;
	outline: 0;
	padding: 0;
	margin: 0;
	}

body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.too-large {
  height: 25px;
  width: 25px;
  background-color: #6C63FF;
  border-radius: 10%;
  padding: .5rem;
}

.purple-highlight {
  color: #6C63FF
}


.profile-pic {
  shape-outside: circle(50%);
}
